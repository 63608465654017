import { opacity } from '@lamimed/pantheon';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

export const customSpinnerModifier = (color: string) => css`
  svg {
    path:first-child {
      stroke: ${transparentize(opacity.medium, color)};
    }

    & > defs {
      > linearGradient {
        > stop {
          stop-color: ${color};
        }
      }
    }
  }
`;

export const SpinnerContainer = styled.div`
  ${({ theme }) => css`
    > div {
      ${customSpinnerModifier(theme.primaryColorElements)}
    }
  `}
`;
