import { Spinner } from '@lamimed/pantheon';

import * as S from './styles';

interface SpinnerProps {
  positionX?: 'left' | 'right' | 'center';
  positionY?: 'top' | 'bottom' | 'center';
  size?: 'small' | 'medium' | 'large' | number;
  expanded?: boolean;
}

export const ThemedSpinner = (props: SpinnerProps) => {
  return (
    <S.SpinnerContainer>
      <Spinner {...props} />
    </S.SpinnerContainer>
  );
};
