import { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useAuthentication } from 'hooks/useAuthentication';
import { useStorage } from 'hooks/useStorage';

import { getAuthorizationQueryParams } from 'utils/getAuthorizationQueryParams';
import { getAuthCredentials } from 'utils/getAuthToken';

export const RESULTS_PARTNER_STORAGE_KEY = 'SymptomCheckerResultsPartnerId';
export const PARTNER_STORAGE_KEY = 'SymptomCheckerPartner';

export const usePartnerId = () => {
  const { getTokenData } = useAuthentication();
  const { get, set } = useStorage();

  const router = useRouter();

  const { query } = useRouter();

  const { partner_id } = getAuthorizationQueryParams(query);

  const tokenData = getTokenData();

  function getPartnerId() {
    if (router.isReady && partner_id) {
      set(PARTNER_STORAGE_KEY, partner_id);

      return partner_id;
    }

    const storagePartnerId = get(PARTNER_STORAGE_KEY);
    const authToken = getAuthCredentials()?.token;

    if (storagePartnerId && authToken) {
      return JSON.parse(storagePartnerId) as string;
    }

    return '';
  }

  const setResultsPartner = (value: string) => {
    return set(RESULTS_PARTNER_STORAGE_KEY, value);
  };

  useEffect(() => {
    if ((!router.isReady || !partner_id) && !tokenData?.partner_id) return;

    set(PARTNER_STORAGE_KEY, partner_id || tokenData?.partner_id);
  }, [router, partner_id, set, tokenData?.partner_id]);

  return {
    getPartnerId,
    setResultsPartner,
  };
};
