import { type ParsedUrlQuery } from 'querystring';

export type AuthorizationQueryParams = {
  partner_id?: string;
  auth_access_token?: string;
};

const getFirstQueryParam = (value: string | string[] | undefined) => {
  return Array.isArray(value) ? value[0] : value;
};

export const getAuthorizationQueryParams = (
  query: ParsedUrlQuery,
): AuthorizationQueryParams => {
  return {
    partner_id: getFirstQueryParam(query.partner_id),
    auth_access_token: getFirstQueryParam(query.auth_access_token),
  };
};
