import { sendToErrorTracking } from './sendToErrorTracking';

export function parseJwt<T>(token: string): T | null {
  try {
    if (!token) return null;

    const base64URL = token.split('.')[1];
    const base64 = base64URL.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    sendToErrorTracking({ error, where: 'parseJwt', context: token });

    return null;
  }
}
