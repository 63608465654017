import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { useStorage } from 'hooks/useStorage';

import { getAuthorizationQueryParams } from 'utils/getAuthorizationQueryParams';
import { parseJwt } from 'utils/parseJwt';

export const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';

type Address = {
  id?: number;
  city: string;
  country: string;
  province: string;
  street: string;
  streetNumber: string;
  zipCode: string;
};

export enum TokenType {
  AUTHENTICATION = 'authentication',
  PASSWORD_RESET = 'password_reset',
  CAPSULA = 'capsula',
}

type User = {
  type: TokenType;
  name: string;
  partner_id?: string;
  surname: string;
  email: string;
  birthdate: Date;
  address: Address;
  exp: number;
};

export function useAuthentication() {
  const { query, isReady, replace } = useRouter();
  const { i18n } = useTranslation();

  const { get, set } = useStorage();

  const { auth_access_token } = getAuthorizationQueryParams(query);

  const loadToken = () => {
    const parsedJwt = parseJwt<User>(JSON.stringify(auth_access_token));

    if (parsedJwt) {
      set(AUTH_ACCESS_TOKEN, auth_access_token);
    }
  };

  const getTokenData = () => {
    if (typeof window === 'undefined') return;

    const authToken = get(AUTH_ACCESS_TOKEN);

    const user = parseJwt<User>(authToken);

    return user;
  };

  const tokenIsValid = () => {
    if (typeof window === 'undefined') return;

    const authToken = get(AUTH_ACCESS_TOKEN);

    const user = parseJwt<User>(authToken);

    if (!user) return false;

    const currentTimeInSeconds = Date.now() / 1000;

    return user.exp > currentTimeInSeconds;
  };

  useEffect(() => {
    if (!isReady || !auth_access_token) return;

    loadToken();

    replace('', undefined, { shallow: true });

    const langQuery =
      ((query.lang || query.locale) as string) || i18n.language || 'it';

    i18n.changeLanguage(langQuery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, auth_access_token]);

  return {
    tokenIsValid,
    getTokenData,
  };
}
