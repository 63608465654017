import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosError } from 'axios';

interface ErrorMessage {
  message: string;
}

interface ErrorTrackingParams {
  error: unknown;
  where: string;
  context: unknown;
  showErrorObj?: boolean;
}

export const sendToErrorTracking = ({
  error,
  where,
  context,
  showErrorObj,
}: ErrorTrackingParams) => {
  if (axios.isAxiosError(error) && error.code != 'ERR_NETWORK') {
    return datadogRum.addError(error, {
      where,
      context,
      error: {
        /* NOTE: this workaround is only required until the next axios release and we will be able
        to pass the error type into isAxiosError
        related issue: https://github.com/axios/axios/pull/4344 */
        message: (error as AxiosError<ErrorMessage>).response?.data.message,
        description: showErrorObj ?? error,
      },
    });
  }

  const err = error as Error;

  datadogRum.addError(err, {
    where,
    context,
    error: {
      message: err.message,
      description: showErrorObj ?? err,
    },
  });
};
