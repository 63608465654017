import dynamic from 'next/dynamic';

import { ProtectedRoute } from 'components/wrappers/ProtectedRoute';

const HowCanWeHelp = dynamic(
  async () => (await import('templates/HowCanWeHelp')).HowCanWeHelp,
  {
    ssr: false,
  },
);

export default function Home() {
  return (
    <ProtectedRoute>
      <HowCanWeHelp />
    </ProtectedRoute>
  );
}
