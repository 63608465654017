import { AUTH_ACCESS_TOKEN } from 'hooks/useAuthentication';

type Credentials = {
  token: string;
};

export function getAuthCredentials(): Credentials | null {
  if (typeof window === 'undefined') return null;

  const localStorageToken = localStorage.getItem(AUTH_ACCESS_TOKEN);

  const token = localStorageToken
    ? (JSON.parse(localStorageToken) as string)
    : '';

  return {
    token,
  };
}
