import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { TokenType, useAuthentication } from 'hooks/useAuthentication';
import { usePartnerId } from 'hooks/usePartnerId';

import { redirectToSso } from 'utils/redirectToSso';

import { ThemedSpinner } from 'components/ThemedSpinner';

export const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { i18n } = useTranslation();
  const { tokenIsValid, getTokenData } = useAuthentication();
  const { isReady, push, pathname } = useRouter();
  const { getPartnerId } = usePartnerId();
  const [isValidatingToken, setIsValidatingToken] = useState(true);

  useEffect(() => {
    if (!isReady) return;

    if (!tokenIsValid()) {
      redirectToSso(i18n.language);

      return;
    }

    const tokenData = getTokenData();

    if (tokenData?.type === TokenType.CAPSULA && pathname === '/') {
      push('/avvertenza');
    }

    setIsValidatingToken(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenIsValid, isReady]);

  useEffect(() => {
    if (!isReady) return;

    getPartnerId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  if (!isReady || isValidatingToken) {
    return <ThemedSpinner expanded />;
  }

  return <>{children}</>;
};
